import { Component, ViewChild } from '@angular/core';
import {NgbCarousel, NgbSlideEvent, NgbSlideEventSource} from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Super Offers';

  images = [
    "/assets/img/img_1.jpg",
    "/assets/img/img_2.jpg",
    "/assets/img/img_3.jpg",
    "/assets/img/img_4.jpg"
    // "/assets/img/img_3.jpg"
  ]

  // public isAdi = '';
  public email = '';

  paused = false;
  unpauseOnArrow = false;
  pauseOnIndicator = false;
  pauseOnHover = true;

  constructor(private http: HttpClient){
  }

  @ViewChild('carousel', {static : true}) carousel: NgbCarousel;

  togglePaused() {
    if (this.paused) {
      this.carousel.cycle();
    } else {
      this.carousel.pause();
    }
    this.paused = !this.paused;
  }

  onSlide(slideEvent: NgbSlideEvent) {
    if (this.unpauseOnArrow && slideEvent.paused &&
      (slideEvent.source === NgbSlideEventSource.ARROW_LEFT || slideEvent.source === NgbSlideEventSource.ARROW_RIGHT)) {
      this.togglePaused();
    }
    if (this.pauseOnIndicator && !slideEvent.paused && slideEvent.source === NgbSlideEventSource.INDICATOR) {
      this.togglePaused();
    }
  }

  public guardar(){
    // console.log("guardar")
    let arroba = this.email.includes('@');
    let punto = this.email.includes('.');  

    if(arroba == true && punto == true ){
      this.http.post("https://vianneymobile-dot-mx-vianney-001.appspot.com/api/suscriptorLandingPage", {
            // this.http.post("http://localhost:8080/api/suscriptorLandingPage", {
            correo : this.email,
            origen : "USA",
            afiliado : "NO APLICA",
            descripcion : "SUPER OFFERS HOMEDECOR BLACK FRIDAY 2019"
          }).subscribe(response => {
              // console.log('Response is ', response);
              Swal.fire(
                '',
                'Thank you!',
                'success'
              ).then((result) => {
                if (result.value) {
                    location.reload();
                }
              }) 
          }, err => {
            // Swal.fire('Lo sentimos no se pudo registrar, intentelo de nuevo.');
            Swal.fire({
              icon: 'error',
              title: '',
              text: 'Sorry, try again.!'
            })
          });    
    }
    else{
      // Swal.fire('Ingrese un correo valido!', 'error');
      Swal.fire({
        icon: 'error',
        title: '',
        text: 'Enter a valid email!'
      })
    }
  }
}
